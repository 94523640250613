// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-index-js": () => import("./../../../src/pages/insights/index.js" /* webpackChunkName: "component---src-pages-insights-index-js" */),
  "component---src-pages-overview-index-js": () => import("./../../../src/pages/overview/index.js" /* webpackChunkName: "component---src-pages-overview-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-talent-index-js": () => import("./../../../src/pages/talent/index.js" /* webpackChunkName: "component---src-pages-talent-index-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */)
}

